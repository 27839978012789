// Run this example by adding <%= javascript_pack_tag 'main' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React from 'react'
import { createRoot } from 'react-dom/client'
import PropTypes from 'prop-types'
import SideBar from '../components/sidebar'
import HomeBlock from '../components/home_block'
import AboutBlock from '../components/about_block'
import PortfolioBlock from '../components/porfolio_block'
import ContactBlock from '../components/contact_block'

const Hello = props => (
  <div className="text-center text-green-500">Hello {props.name}!</div>
)

Hello.defaultProps = {
  name: 'George'
}

Hello.propTypes = {
  name: PropTypes.string
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

document.addEventListener('DOMContentLoaded', () => {
  root.render(
    <div className="main">
      <SideBar />
      <HomeBlock />  
      <AboutBlock />  
      <PortfolioBlock />
      <ContactBlock />
    </div>
  );
});
