import React from 'react'
import PropTypes from 'prop-types'

const Wave = (props) => { 
    return (
        <div className="pb-20">
            <svg className={`${props.name} wave`} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 24 150 28 " preserveAspectRatio="none">
                <defs>
                <path id="gentle-wave"
                    d="M-160 44c30 0 
                        58-18 88-18s
                        58 18 88 18 
                        58-18 88-18 
                        58 18 88 18
                        v44h-352z" />
                </defs>
                <g className="parallax">
                    <use id="wave-path" xlinkHref="#gentle-wave" x="50" y="3"/>
                </g>
            </svg>
        </div>
    );
};

Wave.propTypes = {
    name: PropTypes.string
}

export default Wave;