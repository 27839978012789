import React from 'react'
import CypherText from 'react-cypher-text-loop'
import 'animate.css'

const HomeInfo = () => { 
    return (
        <div className="home-info">
            <h1 className="animate__animated animate__slideInLeft animate__fast">George Haviaridis</h1>
            <h2 className="animate__animated animate__slideInLeft">
                <CypherText textList={['Web Developer','Game Developer','Software Engineer','Graphic Designer']} />
            </h2>
        </div>
    );
};

export default HomeInfo;