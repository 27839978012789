import React from 'react'
import CodeWrapper from '../common/code_wrapper';
import Age from '../home_block/age';

const AboutContent = () => { 
    return (
        <div className="about-content">
            <CodeWrapper tagName="about" className="text-green-400">
                <h2>Hi, I'm <span className="text-sky-400">George</span>. I'm a <span className="text-purple-400"><Age /></span> year old developer.</h2>
                <p>Since 2017 I have worked professionally as a front-end web developer. I worked with many companies around the world to create high performance & 
                    rich interactive websites compatible across all platforms & devices. During that time I have used a lot of modern frameworks aswell as hand-coded 
                    websites and landing pages using <span className="text-red-400">HTML5</span>, <span className="text-blue-400">CSS3</span>,
                    <span className="text-lime-400"> SVG</span> & <span className="text-yellow-400">JavaScript</span>.</p>
            </CodeWrapper>
        </div>
    );
};

export default AboutContent;