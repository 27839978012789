import React from 'react'
import PropTypes from 'prop-types'
import CoolButton from '../common/button';

const ProjectCard = (project) => { 
    const url = project.attributes.external_url;
    return (
        <div className="project-card ">
            <img src={project.attributes.image_url}  alt={project.attributes.name} loading="lazy" />
            <div className="card-content">
                <h5>{project.attributes.name}</h5>
                <p>{project.attributes.description}</p>  
                { url !== "" && <CoolButton text={project.attributes.cta} link={url} /> }
            </div>
        </div>
    );
};

ProjectCard.propTypes = {
    name: PropTypes.string,
    image_url: PropTypes.string,
    description: PropTypes.string,
    external_url: PropTypes.string,
    cta: PropTypes.string
}

export default ProjectCard;