import React from 'react'
import CodeWrapper from '../common/code_wrapper';
import { FaCode, FaServer, FaPaintBrush, FaHtml5, FaCss3Alt, FaSass, FaJsSquare, FaReact, FaBootstrap,  
         FaShopify, FaWordpressSimple, FaNodeJs, FaUnity, FaJava, FaPython, FaFigma } from 'react-icons/fa'
import { SiJquery, SiTailwindcss, SiPhp, SiC, SiCsharp, SiCplusplus, SiWebgl, SiSwift, SiVisualstudiocode, 
         SiRider, SiBlender, SiAdobephotoshop, SiAdobeillustrator, SiAdobepremierepro, 
         SiAdobeaftereffects, SiAdobeindesign } from 'react-icons/si'
import { DiRuby, DiRor } from 'react-icons/di'
import { SpringGrid, measureItems, makeResponsive, layout } from 'react-stonecutter'

const SkillsContent = () => { 

    const Grid = makeResponsive(measureItems(SpringGrid, { measureImages: true }), { maxWidth: 1536 });

    return (
        <div className="skills-content">
        <svg width="0" height="0">
            <linearGradient id="green-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
                <stop stopColor="#56ab2f" offset="0%" />
                <stop stopColor="#a8e063" offset="100%" />
            </linearGradient>
        </svg>
        <svg width="0" height="0">
            <linearGradient id="pink-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
                <stop stopColor="#bc4e9c" offset="0%" />
                <stop stopColor="#f80759" offset="100%" />
            </linearGradient>
        </svg>
        <svg width="0" height="0">
            <linearGradient id="blue-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
                <stop stopColor="#00d2ff" offset="0%" />
                <stop stopColor="#3a7bd5" offset="100%" />
            </linearGradient>
        </svg>
        <CodeWrapper tagName="skills" className="text-red-400">
            <Grid component="ul" columnWidth={384} gutterWidth={20} gutterHeight={20} layout={layout.pinterest}>
                <li id="DevCard">
                    <SkillCard>
                        <FaServer style={{ fill: "url(#pink-gradient)"}} size={100} />
                        <h2>Game and Software Development</h2>
                        <p>I love creating fun and interactive experiences</p>
                        <h3>Dev tools:</h3>
                        <div className="hover-pink">
                            <SkillIcon icon={ <FaUnity size="1.5em" /> } text="Unity" />
                            <SkillIcon icon={ <SiC size="1.5em" /> } text="C" />
                            <SkillIcon icon={ <SiCsharp size="1.5em" /> } text="C#" />
                            <SkillIcon icon={ <SiCplusplus size="1.5em" /> } text="C++" />
                            <SkillIcon icon={ <FaJava size="1.5em" /> } text="Java" />
                            <SkillIcon icon={ <FaPython size="1.5em" /> } text="Python" />
                            <SkillIcon icon={ <SiWebgl size="1.5em" /> } text="WebGL" />
                            <SkillIcon icon={ <SiSwift size="1.5em" /> } text="Swift" />
                            <SkillIcon icon={ <SiVisualstudiocode size="1.5em" /> } text="VS Code" />
                            <SkillIcon icon={ <SiRider size="1.5em" /> } text="Rider" />
                        </div>
                    </SkillCard>
                </li>
                <li id="WebDevCard">
                    <SkillCard>
                        <FaCode style={{ fill: "url(#green-gradient)"}} size={100} />
                        <h2>Web Development</h2>
                        <p>Bringing cool and interesting ideas to life on the browser is a passion</p>
                        <h3>Front-end languages and frameworks:</h3>
                        <div className="hover-green">
                            <SkillIcon icon={ <FaHtml5 size="1.5em" /> } text="HTML5" />
                            <SkillIcon icon={ <FaCss3Alt size="1.5em" /> } text="CSS" />
                            <SkillIcon icon={ <FaSass size="1.5em" /> } text="Sass" />
                            <SkillIcon icon={ <FaJsSquare size="1.5em" /> } text="Javascript" />
                            <SkillIcon icon={ <SiJquery size="1.5em" /> } text="JQuery" />
                            <SkillIcon icon={ <FaReact size="1.5em" /> } text="React" />
                            <SkillIcon icon={ <FaBootstrap size="1.5em" /> } text="Bootstrap" />
                            <SkillIcon icon={ <SiTailwindcss /> } text="Tailwind" />
                            <SkillIcon icon={ <FaShopify size="1.5em" /> } text="Shopify" />
                            <SkillIcon icon={ <FaWordpressSimple size="1.5em" /> } text="Wordpress" />
                        </div>
                        <h3>Back-end languages and frameworks:</h3>
                        <div className="hover-green">
                            <SkillIcon icon={ <SiPhp size="1.5em" /> } text="PHP" />
                            <SkillIcon icon={ <DiRuby size="1.5em" /> } text="Ruby" />
                            <SkillIcon icon={ <DiRor size="1.5em" /> } text="Ruby on Rails" />
                            <SkillIcon icon={ <FaNodeJs size="1.5em" /> } text="NodeJS" />
                        </div>
                    </SkillCard>
                </li>
                <li id="GDCard">
                    <SkillCard>
                        <FaPaintBrush style={{ fill: "url(#blue-gradient)"}} size={100} />
                        <h2>Graphic Design</h2>
                        <p>I like to create logos, graphics and renders</p>
                        <h3>Design tools:</h3>
                        <div className="hover-blue">
                            <SkillIcon icon={ <SiAdobephotoshop size="1.5em" /> } text="Photoshop" />
                            <SkillIcon icon={ <SiAdobeillustrator size="1.5em" /> } text="Illustrator" />
                            <SkillIcon icon={ <SiAdobepremierepro size="1.5em" /> } text="Premiere" />
                            <SkillIcon icon={ <SiAdobeaftereffects size="1.5em" /> } text="After Effects" />
                            <SkillIcon icon={ <SiAdobeindesign size="1.5em" /> } text="In Design" />
                            <SkillIcon icon={ <SiBlender size="1.5em" /> } text="Blender" />
                            <SkillIcon icon={ <FaFigma size="1.5em" /> } text="Figma" /> 
                        </div>                  
                    </SkillCard> 
                </li>    
            </Grid>
        </CodeWrapper>
        </div>
    );
};

const SkillIcon = ({ icon, text }) => (
    <div className="skill-icon group">
        { icon }
        <span className="skill-popup-wrapper">
            <span className="skill-popup group-hover:scale-100">
                { text }
            </span>
        </span>
    </div>
);

const SkillCard = ({className, children}) => { 
    return (
        <div className={`skill-card ${ className }`}>
            {children}
        </div>
    );
};

export default SkillsContent;