import React, { useState, useEffect } from 'react'
import moment from 'moment'
import 'moment-timezone'

const Age = () => { 
  const birthday = "1993-07-14T00:00+1000";
  const [timeAlive, setTimeAlive] = useState(Date.now());
  

  useEffect(() => {
    const interval = setInterval(() => setTimeAlive(moment().diff(birthday, "years", true)), 50);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
      <span className="age">
          { timeAlive.toString().substring(0, 12) }
      </span>
  );
};

export default Age;