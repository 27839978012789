import React from 'react'
import AboutContent from './about_block/about_content'
import SkillsContent from './about_block/skills_content';
import Wave from './common/wave';

const AboutBlock = () => { 
    return (
        <div id="about" className="about-block">
            <Wave name="about-wave" />
            <AboutContent />
            <SkillsContent />
        </div>
    );
};

export default AboutBlock;