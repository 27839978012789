import React from 'react'
import Wave from './common/wave'
import Contact from './contact_block/contact';

const ContactBlock = () => { 
    return (
        <div className="contact-block">
            <Wave name="contact-wave" />
            <Contact />
        </div>
    );
};

export default ContactBlock;