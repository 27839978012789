import React, { Fragment } from 'react'

const CodeWrapper = ({className, tagName, children}) => { 
    return (
        <Fragment>
        <h1 className={`element-open ${ className }`}>{ tagName }</h1>
        <div className="element-indent">
            {children}
        </div>
        <h1 className={`element-close ${ className }`}>{ tagName }</h1> 
        </Fragment>
    );
};

export default CodeWrapper;