import React from 'react'
import CoolButton from '../common/button';
import CodeWrapper from '../common/code_wrapper';


const Contact = () => { 
    return (
        <div className="contact">
            <CodeWrapper tagName="contact" className="text-blue-500">                
                <h2>If you have anything to say please contact me I would be happy to hear it at <a href="mailto:ghaviaridis@gmail.com">ghaviaridis@gmail.com</a>.</h2>
                <CoolButton text="Contact me" link="mailto:ghaviaridis@gmail.com" />
            </CodeWrapper>
        </div>
    );
};

export default Contact;