import { useEffect } from "react";
import useLocalStorage from "./useLocalStorage";

const useDarkMode = () => {
    const [enabled, setEnabled] = useLocalStorage("dark-mode", true)
    const isEnabled = typeof enabled !== "undefined" ? enabled : false;

    useEffect(() => {
        const className = "dark";
        const bodyClass = document.body.classList;

        isEnabled ? bodyClass.add(className) : bodyClass.remove(className);
    }, [enabled, isEnabled]);

    return [enabled, setEnabled];
};

export default useDarkMode;