import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ProjectCard from './portfolio_block/project_card'
import Wave from './common/wave'
import { SpringGrid, measureItems, makeResponsive, layout } from 'react-stonecutter'


import CodeWrapper from './common/code_wrapper'

function PortfolioBlock() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        axios.get('/api/v1/projects.json')
            .then(response => {
                setProjects(response.data.data.reverse());
            })
            .catch(response => console.log(response));
    }, [projects.length]);

    const cards = projects.map(item => {
        return (<li datum={item} key={item.attributes.name}><ProjectCard attributes={item.attributes} /></li>);
    });

    const Grid = makeResponsive(measureItems(SpringGrid, { measureImages: true }), { maxWidth: 1536 });

    return (
        <div id="portfolio" className="portfolio-block">
            <Wave name="portfolio-wave" />
            <div className="portfolio-content">
                <CodeWrapper tagName="projects" className="text-purple-300">
                    <Grid component="ul" columnWidth={384} gutterWidth={20} gutterHeight={20} layout={layout.pinterest}>
                        {cards}
                    </Grid>
                </CodeWrapper>
            </div>
        </div>
    );
}

export default PortfolioBlock;