import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const CoolButton = (props) => { 
    return (
        <Fragment>
            <a href={ props.link } target="_blank" className="button group group-hover:from-purple-500 group-hover:to-pink-500">
                <span className={`button-inner ${ props.bgclass }`}>
                    <span className="button-text group-hover:before:py-[200%]">
                        { props.text }
                    </span>
                </span>
            </a>
        </Fragment>
    );
};

CoolButton.propTypes = {
    link: PropTypes.string,
    text: PropTypes.string,
}

//group-hover:bg-opacity-0
export default CoolButton;