import React from 'react'
import HomeInfo from './home_block/home_info'

const HomeBlock = () => { 
    return (
        <div id="home" className="home-block">
            <HomeInfo />
        </div>
    );
};

export default HomeBlock;