import React from 'react'
import { Link } from 'react-scroll'
import { FaHome, FaIdCard, FaJournalWhills, FaRegEnvelope, FaSun, FaMoon } from "react-icons/fa"
import useDarkMode from '../hooks/useDarkMode'

const SideBar = () => { 
    return (
        <div className="sidebar">
            <Link to="home" spy={true} smooth={true} offset={0} duration={500}>
                <SideBarItem icon={ <FaHome size="24" />  } text="Home" />
            </Link>
            <Link to="about" spy={true} smooth={true} offset={0} duration={500}>
                <SideBarItem icon={ <FaIdCard size="24" /> } text="About" />
            </Link>
            <Link to="portfolio" spy={true} smooth={true} offset={0} duration={500}>
                <SideBarItem icon={ <FaJournalWhills size="24" /> } text="Portfolio" />
            </Link>
            <Link to="contact" spy={true} smooth={true} offset={0} duration={500}>
                <SideBarItem icon={ <FaRegEnvelope size="24" /> } text="Contact" />
            </Link>
            <SideBarDarkMode text="Toggle Dark Mode" />
        </div>
    );
};

const SideBarItem = ({ icon, text }) => (
    <div className="sidebar-item group">
        { icon }

        <span className="sidebar-popup group-hover:scale-100">
            { text }
        </span>
    </div>
);

const SideBarDarkMode = ({ text }) => {
    const [darkMode, setDarkMode] = useDarkMode();
    const changeMode = () => setDarkMode(!darkMode); 

    return (
        <div className="dark-mode-toggle group" onClick={changeMode}>
            { darkMode ? ( <FaSun size="16" /> ) : ( <FaMoon size="16" /> ) }

            <span className="sidebar-popup group-hover:scale-100">
                { text }
            </span>
        </div>
    );
};


export default SideBar